import React from 'react';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import {careersEnabled, getLink, getRoutes} from "../../components/shared/routes";
import OfferPage from "../../components/shared/OfferPage/OfferPage";
import IBDDOLogo
    from "../../images/Careers/deeplai-career-international_business_development_manager_direct_operations.svg";

const CareersManagerDirectOperations = () => {
    const routes = getRoutes();

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }
    if (!careersEnabled.internationalBusinessDevelopmentManagerDirectOperations) {
        return <RedirectTo url={getLink([routes.Careers])}/>;
    }

    return (
        <OfferPage
            image={IBDDOLogo}
            imageAlt={'deeplai career International Business Development Manager Direct Operations'}
            name={routes.CareersManagerDirectOperations.pageTitle}
            scopeOfDuties={<><p>present, promote and sell Deeplai business solutions to the forestry industry customers
                and business partners</p>
                <p>establish, develop and maintain professional business customer relationships</p>
                <p>expedite the resolution of customer problems and maximize satisfaction level</p>
                <p>achieve agreed upon sales targets and outcomes within a KPI schedule</p>
                <p>analyze the market’s potential, unique selling points</p>
                <p>create and supply management with business reports on customer requirements and technology
                    expectations</p>
                <p>keep abreast of competitive market offerings</p></>}

            ourRequirements={<><p>bachelor’s degree</p>
                <p>proven work experience in Sales and Business Development</p>
                <p>highly motivated and target driven with a proven track record in sales</p>
                <p>excellent selling, communication and negotiation skills</p>
                <p>prioritizing, time management and organizational skills</p>
                <p>ability to create and deliver presentations tailored to the audience</p>
                <p>relationship management skills and openness to feedback</p>
                <p>fluency in English</p></>}
            whatYouCanExpectFromUs={<><p>working in a team inspired and fascinated by AI/ML and product engineering </p>
                <p>friendly atmosphere, daily cooperation with open minded people</p>
                <p>various, interesting projects in cutting-edge technologies</p>
                <p>real personal impact on implemented projects</p>
                <p>renumeration package adequate to your experience</p>
                <p>flexible working hours</p></>}
        />
    );
};

export default CareersManagerDirectOperations;
